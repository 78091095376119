import React from 'react';
import Layout from 'components/Layout';
import Container from 'components/Container';
import Seo from "../../components/Seo";
import {Link} from "gatsby";

import banner from "../../images/datanyze/datanyze-banner.png";
import homepage from "../../images/datanyze/home.png";
import pricing from "../../images/datanyze/pricing.png";
import about from "../../images/datanyze/about.png";
import features from "../../images/datanyze/features.png";
import mobile from "../../images/feature-datanyze2.png";

function FadeInSection(props) {
    const [isVisible, setVisible] = React.useState(true);
    const domRef = React.useRef();
    React.useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => setVisible(entry.isIntersecting));
        });
        observer.observe(domRef.current);
        return () => observer.unobserve(domRef.current);
    }, []);
    return (
            <div
                    className={`fade-in-section ${isVisible ? 'is-visible' : ''}`}
                    ref={domRef}
            >
                {props.children}
            </div>
    );
}

class Datanyze extends React.Component {

    render() {
        return (
                <Layout pageName="datanyze">
                    <Seo
                            title={'Datanyze'}
                    />
                    <Container>
                        <section>
                            <div className="extra-large-wrapper mt-16 sm:mt-36">
                                <h1 className="font-serif text-left text-4xl sm:text-9xl leading-none sm:-mt-4">Datanyze</h1>
                                <div className="sm:flex justify-between mt-16 sm:mt-24">
                                    <div className="flex">
                                        <div className="">
                                            <h3 className="font-mono italic text-sm featured-color">Role</h3>
                                            <ul className="w-auto mt-2 inline-flex flex-col items-start">
                                                <li className="border rounded-full py-1 px-4 w-auto font-mono italic text-sm">Brand Identity</li>
                                                <li className="border rounded-full py-1 px-4 mt-3 font-mono italic text-sm">Website Design</li>
                                                <li className="border rounded-full py-1 px-4 mt-3 font-mono italic text-sm">Frontend Development</li>
                                            </ul>
                                        </div>
                                        <div className="ml-24">
                                            <h3 className="font-mono italic text-sm featured-color">Date</h3>
                                            <h4 className="mt-2">2020</h4>
                                        </div>
                                    </div>
                                    <div className="mt-8 sm:mt-0 sm:w-2/5">
                                        <p className="leading-normal">Datanyze is a B2B sales prospecting company providing affordable access to contact information. They had a logo created and I was tasked with establishing the style of our marketing assets. The primary deliverable was the marketing website, which I designed with my team as well as did the frontend development to bring it all to life with scroll-based animations.</p>
                                        <a href="https://www.datanyze.com/" target="_blank" rel="noreferrer" className="mt-8 block featured-color hover:underline">Launch Website -></a>
                                    </div>
                                </div>
                                <FadeInSection>
                                    <div className="mt-16">
                                        <img src={banner} alt="project images"/>
                                    </div>
                                </FadeInSection>
                            </div>
                            <div className="extra-large-wrapper mt-16 sm:mt-32 grid gap-8 lg:gap-16 sm:grid-cols-2 content-start">
                                <FadeInSection>
                                    <img src={homepage} alt="" className="rounded-2xl border-4 border-lightgrey"/>
                                </FadeInSection>
                                <FadeInSection>
                                    <img src={features} alt="" className="rounded-2xl border-4 border-lightgrey"/>
                                </FadeInSection>
                                <FadeInSection>
                                    <img src={about} alt="" className="rounded-2xl border-4 border-lightgrey"/>
                                </FadeInSection>
                                <FadeInSection>
                                    <img src={pricing} alt="" className="rounded-2xl border-4 border-lightgrey"/>
                                </FadeInSection>
                            </div>
                            <div className="extra-large-wrapper mt-16">
                                <FadeInSection>
                                    <img src={mobile} alt="project images"/>
                                </FadeInSection>
                            </div>
                        </section>
                        <section>
                            <FadeInSection>
                                <div className="extra-large-wrapper text-center relative mt-16 sm:mt-32 sm:mb-16">
                                    <Link to="/work/zoominfo" className="text-3xl sm:text-6xl font-serif border-b-2 border-white hover:border-black">
                                        next project — ZoomInfo
                                    </Link>
                                </div>
                            </FadeInSection>
                        </section>
                    </Container>
                </Layout>
        );
    }
}

export default Datanyze;
